(function() {

  // ADMIN TinyMCE
  tinymce.init({
    selector: ".richtext",
    setup: function (editor) {
      editor.on('change', function() {
        editor.save();
      });
    },
    extended_valid_elements : "i[class]",
    plugins: [
      "autolink lists link charmap anchor",
      "searchreplace visualblocks",
      "table contextmenu paste code"
    ],
    // relative_urls : false,
    // remove_script_host : false,
    // document_base_url: "http://www.companylawsolutions.co.uk/",
    menubar: "edit insert view format table tools",
    toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link"
  });


  //UPDATE SECTION
  $('form.update-section, form.update-service').on('submit', function(e) {

    var form = $(this);
    var method = form.find('input[name="_method"]').val() || 'POST';
    var url = form.prop('action');

    $.ajax({
      type: method,
      url: url,
      data: form.serialize(),
      success: function(data) {

        var section = data['section'];
        var span = form.find('span.element_updated_at');

        span
          .empty()
          .fadeOut(50)
          .append(data['element_updated_at'])
          .delay(300)
          .fadeIn(50);

        // alert('all done');
        $('.flash-message').fadeIn(200).delay(1500).fadeOut(200);

      },
      error: function() {
        $('.error_msg').fadeIn(300);
      },
    });

    e.preventDefault();

  });

  $.fn.unPublishService = function() {
    // PUBLISH/UNPUBLISH SERVICE MODAL
    $('.unpublish-service-trigger').click(function(e) {
      e.preventDefault();

      $.ajax({
        url: $(this).attr('href'),
        success: function(data) {

          $('#unpublishServiceModal form').attr('action', '/dashboard/service/unpublish/' + data['service']['id']);

          $('#unpublishServiceModal .service-title').empty();
          $('#unpublishServiceModal .service-title').append(data['service']['title']);

          $('#unpublishServiceModal .service-summary').empty();
          $('#unpublishServiceModal .service-summary').append(data['service']['secondary_title']);

          $('#unpublishServiceModal .service-slug').empty();
          $('#unpublishServiceModal .service-slug').append(data['service']['slug']);

          // show modal
          $('#unpublishServiceModal').modal();
        }
      });
      return false;
    });
  };

  $('.unpublish-service-trigger').unPublishService();

  $('#unpublishServiceModal form').on('submit', function(e) {

      e.preventDefault();

      var form = $(this);
      var method = form.find('input[name="_method"]').val() || 'POST';
      var url = form.prop('action');

      $.ajax({
        type: method,
        url: url,
        data: form.serialize(),
        success: function(data) {

          id = data['service']['id'];

          // Find the slug on the page and update it.
          $('#service_' +  id + ' .unpublish-service-trigger')
            .removeClass('btn-warning unpublish-service-trigger')
            .addClass('btn-success publish-service-trigger')
            .text('Publish')
            .off('click')
            .publishService();

          $('.flash-saved').fadeIn(300);

          setTimeout(function() {
              $('#unpublishServiceModal').modal('toggle');
              $('.flash-saved').fadeOut(300);
          }, 1000);

        }
      });
      return false;

  });

  $.fn.publishService = function() {
    // PUBLISH SERVICE MODAL
    $('.publish-service-trigger').click(function(e) {
      e.preventDefault();

      $.ajax({
        url: $(this).attr('href'),
        success: function(data) {

          $('#publishServiceModal form').attr('action', '/dashboard/service/publish/' + data['service']['id']);

          $('#publishServiceModal .service-title').empty();
          $('#publishServiceModal .service-title').append(data['service']['title']);

          $('#publishServiceModal .service-summary').empty();
          $('#publishServiceModal .service-summary').append(data['service']['secondary_title']);

          $('#publishServiceModal .service-slug').empty();
          $('#publishServiceModal .service-slug').append(data['service']['slug']);

          // show modal
          $('#publishServiceModal').modal();
        }
      });
      return false;
    });
  }

  $('.publish-service-trigger').publishService();

  $('#publishServiceModal form').on('submit', function(e) {

    e.preventDefault();

    var form = $(this);
    var method = form.find('input[name="_method"]').val() || 'POST';
    var url = form.prop('action');

    $.ajax({
      type: method,
      url: url,
      data: form.serialize(),
      success: function(data) {

        id = data['service']['id'];

        // Find the slug on the page and update it.
        $('#service_' +  id + ' .publish-service-trigger')
          .removeClass('btn-success publish-service-trigger')
          .addClass('btn-warning unpublish-service-trigger')
          .text('Unpublish')
          .off('click')
          .unPublishService();

        $('.flash-saved').fadeIn(300);

        setTimeout(function() {
            $('#publishServiceModal').modal('toggle');
            $('.flash-saved').fadeOut(300);
        }, 1000);

      }
    });
    return false;

  });


  // LEGACY URL
  $('.edit-url').click(function(e) {

    e.preventDefault();

    $.ajax({
      url: $(this).attr('href'),
      success: function(data) {

        //$('#editUrlModal form input[name=id]').val(data['section']['id']);
        $('#editUrlModal form').attr('action', '/dashboard/legacy-urls/update/' + data['url']['id']);
        $('#editUrlModal form input[name=slug]').val(data['url']['slug']);
        $('#editUrlModal form input[name=redirect_url]').val(data['url']['redirect_url']);

        // show modal
        $('#editUrlModal').modal();
      }
    });
    return false;

  });

  $('.delete-url').click(function(e) {

    e.preventDefault();

    $.ajax({
      url: $(this).attr('href'),
      success: function(data) {

        //$('#editUrlModal form input[name=id]').val(data['section']['id']);
        $('#deleteUrlModal form').attr('action', '/dashboard/legacy-urls/delete/' + data['url']['id']);
        $('#deleteUrlModal form input[name=slug]').val(data['url']['slug']);
        $('#deleteUrlModal form input[name=redirect_url]').val(data['url']['redirect_url']);

        // show modal
        $('#deleteUrlModal').modal();
      }
    });
    return false;

  });

  $('#editUrlModal form').on('submit', function(e) {

        e.preventDefault();

        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var url = form.prop('action');

        $.ajax({
          type: method,
          url: url,
          data: form.serialize(),
          success: function(data) {

            id = data['url']['id'];

            // Find the slug on the page and update it.
            $('#url_' +  id + ' .url-slug a').attr('href', 'http://www.companylawsolutions.co.uk/' + data['url']['slug']);
            $('#url_' +  id + ' .url-slug a').text(data['url']['slug']);
            $('#url_' +  id + ' .url-redirect').empty().append(data['url']['redirect_url']);
            $('#url_' +  id + ' .url-updated-at').empty().append(data['url']['updated_at']);

            $('.flash-saved').fadeIn(300);

            setTimeout(function() {
                $('#editUrlModal').modal('toggle');
                $('.flash-saved').fadeOut(300);
            }, 1000);

          }
        });
        return false;

    });




  // EDIT/UPDATE PRODUCTS
  $('.edit-product').click(function(e){

    e.preventDefault();

    $.ajax({
      url: $(this).attr('href'),
      success: function(data) {

        $('#editProductModal form').attr('action', '/dashboard/product/update/' + data['product']['id']);
        $('#editProductModal .product-title').empty();
        $('#editProductModal .product-title').append(data['product']['title']);
        $('#editProductModal form input[name=title]').val(data['product']['title']);
        $('#editProductModal form input[name=display_order]').val(data['product']['display_order']);
        $('#editProductModal form input[name=price]').val(data['product']['price']);

        if (data['product']['purchase_option'] == 'bin') {
          $('#editProductModal form input[value=bin]').prop('checked', true);
        } else {
          $('#editProductModal form input[value=quote]').prop('checked', true);
        }

        // show modal
        $('#editProductModal').modal();
      }
    });
    return false;

  });

  $('.delete-product').click(function(e){

    e.preventDefault();

    $.ajax({
      url: $(this).attr('href'),
      success: function(data) {

        $('#deleteProductModal form').attr('action', '/dashboard/product/update/' + data['product']['id']);
        $('#deleteProductModal .product-title').empty();
        $('#deleteProductModal .product-title').append(data['product']['title']);
        $('#deleteProductModal form input[name=title]').val(data['product']['title']);
        $('#deleteProductModal form input[name=display_order]').val(data['product']['display_order']);
        $('#deleteProductModal form input[name=price]').val(data['product']['price']);

        // show modal
        $('#deleteProductModal').modal();
      }
    });
    return false;

  });

  $('#editProductModal form').on('submit', function(e) {

        e.preventDefault();

        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var url = form.prop('action');

        $.ajax({
          type: method,
          url: url,
          data: form.serialize(),
          success: function(data) {

            id = data['product']['id'];

            // Find the product on the page and update it.
            $('#product_' +  id + ' .display_order').empty().append(data['product']['display_order']);
            $('#product_' +  id + ' .purchase_option').empty().append(data['product']['purchase_option']);
            $('#product_' +  id + ' .price').empty().append(data['product']['price']);

            $('.flash-saved').fadeIn(300);

            setTimeout(function() {
                $('#editProductModal').modal('toggle');
                $('.flash-saved').fadeOut(300);
            }, 1000);

          }
        });
        return false;

    });




})();